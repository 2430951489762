<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Test</strong>
      </div>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <list-tree
          :tree-data="comments"
          :pagination="reviews.pagination"
          :delete-comment="deleteComment"
          :ban-user="banUser"
          :reply-to-comment="replyToComment"
          :loading="loading"
        ></list-tree>
      </div>
    </div>
  </section>
</template>

<script>
import ListTree from '@/components/MyComponents/Comments/ListTree'

export default {
  components: {
    ListTree,
  },
  data() {
    return {
      id: 255,
      putId: 353,
      reviews: {
        pagination: {
          pageSize: 10,
        },
      },
      daysBanTo: 1,
      loading: false,
      comments: [
        {
          cmt_id: 5,
          uer_id: 2,
          created_at: '2019-10-26 19:30:16 +0200',
          updated_at: '2019-10-26 19:30:22 +0200',
          cmt_message: 'few',
          cmt_cmt_id: null,
          put_id: 353,
          blg_id: null,
          is_send_notification: true,
          rvw_id: null,
          children_count: 0,
          user: {
            uer_id: 2,
            lge_id: 1,
            email: 'nchmelar@example.com',
            avatar: 'boy-blue-shirt.svg',
            uer_type: 'AMR',
            uer_is_active: true,
            uer_is_newsletter: null,
            created_at: '2012-12-24 12:27:27+01',
            banned_from: null,
            banned_to: null,
            uer_first_name: 'Natálie',
            uer_last_name: 'Matyášová',
            uer_session_id: null,
            ip_addresses: [],
          },
        },
        {
          cmt_id: 3,
          uer_id: 1,
          created_at: '2019-10-24 22:38:30 +0200',
          updated_at: '2019-10-24 22:38:32 +0200',
          cmt_message: 'je',
          cmt_cmt_id: null,
          put_id: 353,
          blg_id: null,
          is_send_notification: false,
          rvw_id: null,
          children_count: 1,
          user: {
            uer_id: 1,
            lge_id: 1,
            email: 'danielbill95@gmail.com',
            avatar: 'boy-brown-shirt.svg',
            uer_type: 'AMR',
            uer_is_active: true,
            uer_is_newsletter: null,
            created_at: '2017-04-28 11:11:03+02',
            banned_from: null,
            banned_to: null,
            uer_first_name: 'Jaromír',
            uer_last_name: 'Macura',
            uer_session_id: null,
            ip_addresses: [],
          },
        },
      ],
    }
  },
  computed: {
  },
  methods: {
    banUser(userRegistered) {

    },
    deleteComment(comment) {
      this.loading = true
      this.$store.dispatch('comment/delete', [comment.cmt_id])
        .then(() => {
          this.$store.commit('product/DELETE_COMMENT', comment)
        })
        .catch(error => {
          let errorBag
          if (error.response === undefined) {
            errorBag = {
              message: 'ERROR 520: Něco se pokazilo',
              description: error,
            }
          } else if (error.response.data === undefined) {
            errorBag = {
              message: 'ERROR ' + error.response.status + ': Něco se pokazilo',
              description: error.response,
            }
          } else {
            let description = ''
            for (const errorKey in error.response.data.errors) {
              description += error.response.data.errors[errorKey]
            }
            errorBag = {
              message: 'ERROR ' + error.response.status + ': ' + error.response.data.message,
              description: description,
            }
          }
          this.$notification.error(errorBag)
        })
        .finally(() => {
          this.loading = false
        })
    },
    replyToComment(comment) {
      if (comment.children === undefined) {
        comment.children = []
      }
      comment.children.push({
        cmt_message: this.cmt_message,
        cmt_cmt_id: comment.cmt_id,
        put_id: this.putId,
        blg_id: null,
        cmt_id: this.id++,
        uer_id: 1,
        created_at: '2019-10-24 22:38:30 +0200',
        updated_at: '2019-10-24 22:38:32 +0200',
        is_send_notification: false,
        rvw_id: null,
        children_count: 1,
        user: {
          uer_id: 1,
          lge_id: 1,
          email: 'danielbill95@gmail.com',
          avatar: 'boy-brown-shirt.svg',
          uer_type: 'AMR',
          uer_is_active: true,
          uer_is_newsletter: null,
          created_at: '2017-04-28 11:11:03+02',
          banned_from: null,
          banned_to: null,
          uer_first_name: 'Jaromír',
          uer_last_name: 'Macura',
          uer_session_id: null,
          ip_addresses: [],
        },
      })
      comment.children_count += 1
      this.replies = null
      this.cmt_message = ''
    },
  },
  created() {
  },
  watch: {
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
